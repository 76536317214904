var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      !_vm.isPreview
        ? _c(
            "a-tabs",
            {
              on: { change: _vm.callback },
              model: {
                value: _vm.activeKey,
                callback: function ($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey",
              },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "待开具" } },
                [
                  _vm.keyval == 1
                    ? _c("CertificateTabel", {
                        attrs: { keyval: _vm.keyval },
                        on: { nextStep: _vm.nextStep, gettemId: _vm.gettemId },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "已开具" } },
                [
                  _vm.keyval == 2
                    ? _c("CertificateTabel", {
                        ref: "CertificateTabel",
                        attrs: { keyval: _vm.keyval },
                        on: { detail: _vm.getDetail },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isPreview
        ? _c(
            "div",
            [
              _c("CertificatePreview", {
                attrs: {
                  "select-info": _vm.selectInfo,
                  "temp-id": _vm.tempId,
                  "details-info": _vm.detailsInfo,
                },
                on: { back: _vm.comeBack },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }